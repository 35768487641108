import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Features</AnchorLink>
  <AnchorLinks small mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">Server Deployments</AnchorLink>
    <AnchorLink mdxType="AnchorLink">Other</AnchorLink>
  </AnchorLinks>
    </AnchorLinks>
    <InlineNotification mdxType="InlineNotification">
  To see images/gifs/vidoes below, please connect to VPN
    </InlineNotification>
    <h2>{`Features`}</h2>
    <h3>{`Server Deployments`}</h3>
    <ul>
      <li parentName="ul">{`Change Product Version. Added a new button to upgrade ES server product version from current to newer.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.08.10%252FchangeProduct.gif&isNativeBrowsing=false",
          "alt": "Workflow Step actions"
        }}></img></li>
    </ul>
    <ul>
      <li parentName="ul">{`Add sorting to Execute Workflow menu. If component is AIO, display only AIO workflows. If it is a 3-box deployment, display only 3-box workflows and supress AIO workflows.
`}<img parentName="li" {...{
          "src": "http://artifactory.bddevops.io/ui/api/v1/download?repoKey=devops-shared-generic&path=documentation%252FPP%252Freleases%252Fv2023.08.10%252FsortWorkflow.gif&isNativeBrowsing=false",
          "alt": "Execute action log"
        }}></img></li>
    </ul>
    <h3>{`Other`}</h3>
    <ul>
      <li parentName="ul">{`Bug fixes`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      